import HeroSection from './HeroSection.js';

function MainBody() {
  return (  
  <main className="main">
      <HeroSection />
  </main>
  );
}

export default MainBody;

import Header from './Header.js';
import MainBody from './MainBody.js';

function App() {
  return (
    <div className="App">
      <Header />
      <MainBody />
    </div>
  );
}

export default App;
